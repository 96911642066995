export * from "gatsby-theme-mate/src/components/Card";
import styled from 'styled-components';
import { Card as CardRebass } from 'rebass/styled-components';

export const Card = styled(CardRebass).attrs({
  bg: 'background',
  boxShadow: 0,
})`
  position: relative;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.8);
  transition: all 0.25s;
  top: 0;
  height: 100%;
  border-radius: 8px;

  &:hover {
    top: -10px;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.8);
  }
`;
