import { graphql, useStaticQuery } from 'gatsby';
import { DevAuthor, DevArticles } from '../types';

export type QueryResponse = {
  allDevArticles: {
    nodes: {
      article: {
        id: number;
        title: string;
        description: string;
        url: string;
        readable_publish_date: string;
        collection_id: number;
        cover_image: string;
        user: {
          username: string;
          name: string;
        }
      };
    }[];
  };
};

type Response = {
  articles: DevArticles[];
  author: DevAuthor;
};

export const useDevToQuery = (): Response => {
  const { allDevArticles } = useStaticQuery<QueryResponse>(graphql`
    query MyQuery {
      allDevArticles {
        nodes {
          article {
            id
            title
            description
            url
            readable_publish_date
            collection_id
            cover_image
            user {
              username
              name
            }
          }
        }
      }
    }
  `);

  const { nodes: nodes } = allDevArticles;
  const articles = nodes.map((n) => ({
    title: n.article.title,
    text: n.article.description,
    cover: n.article.cover_image,
    url: n.article.url,
    date: n.article.readable_publish_date,
  }));
  const author = nodes[0]?.article.user;

  return {
    articles,
    author,
  };
};
