import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Section from 'gatsby-theme-mate/src/components/Section';
import { CardContainer } from '../components/Card';
import Triangle from 'gatsby-theme-mate/src/components/Triangle';
import { useDevToQuery } from '../queries/useDevToQuery';
import { MorePosts, Post } from '../components/Post';
import { SECTION } from '../utils/constants';

const Writing = () => {
  const { articles, author } = useDevToQuery();

  return (
    <Section.Container id={SECTION.writing} Background={Background}>
      <Section.Header name={SECTION.writing} icon="✍️" label="writing" />
      <CardContainer minWidth="300px">
        <Fade direction="down" triggerOnce cascade damping={0.1} duration={500}>
          {articles.map((p) => (
            <Post {...p} key={p.url} />
          ))}
          {/* <MorePosts author={author} number={6} /> */}
        </Fade>
      </CardContainer>
    </Section.Container>
  );
};

const Background = () => (
  <>
    <Triangle
      color="muted"
      height={['15vh', '10vh']}
      width={['100vw', '100vw']}
      position="top-left"
    />

    <Triangle
      color="secondary"
      height={['50vh', '40vh']}
      width={['70vw', '40vw']}
      position="bottom-left"
    />

    <Triangle
      color="primary"
      height={['40vh', '15vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
  </>
);

export default Writing;
